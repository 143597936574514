var render, staticRenderFns
import script from "./BreadcrumbFirstItem.js?vue&type=script&lang=js&"
export * from "./BreadcrumbFirstItem.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BreadcrumbFirstItem.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fblog%2Fcomponents%2Fmolecule%2FBreadcrumbFirstItem%2FBreadcrumbFirstItem.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports