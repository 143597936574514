import { merge } from 'lodash'
import themeSettings from '~/themes/nobilis/settings'

const overloadSettings = {
  components: {
    ArticleBox: {
      articleInfo: {
        author: {
          enable: true,
          icon: 'person',
        },
        category: {
          enable: true,
          icon: 'folder',
          maxCount: 2,
        },
        date: {
          enable: true,
          icon: 'calendar',
        },
        enable: false,
        tag: {
          enable: true,
          icon: 'folder',
          maxCount: 2,
        },
      },
      image: {
        crop: true,
        enable: true,
        height: 220, // Change SASSs variable $ArticleBox-image-height
        position: 'top', // top, left
        width: 700, // Change SASSs variable $ArticleBox-image-width
      },
      linkReadMore: {
        button: 'link', // link, primary or secondary
        enable: false,
      },
      perex: {
        enable: true,
      },
      showIcons: false,
    },
    ArticleList: {
      perLine: {
        xs: 1,
        lg: 3,
      },
      perPage: 6,
    },
    AuthorBox: {
      image: {
        crop: true,
        height: 80,
        width: 80,
      },
    },
    ContentHeader: {
      categoryDropdown: {
        enable: true,
      },
      image: {
        required: false,
      },
      tagDropdown: {
        enable: true,
      },
    },
    PageArticleDetail: {
      articleInfo: {
        author: {
          showLink: true,
        },
      },
      backToArticles: {
        enable: false,
        button: 'light', // link, primary or secondary
      },
      footer: {
        authorStyle: 'AuthorBox', // `AuthorBox`, `inline`
        enable: true,
      },
      header: {
        enable: true,
        enableBreadcrumbsFirstItem: false,
      },
      sidebar: {
        enable: false,
      },
      showIcons: false,
    },
    PageArticleList: {
      header: {
        enable: true,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: false,
        position: 'right', // `left`, `right`
      },
    },
    PageArticleListAuthor: {
      header: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
      sidebar: {
        enable: false,
      },
      image: {
        crop: false,
        enable: true,
        height: 250, // Change SASSs variable $ArticleBox-image-height
        position: 'top', // top, left
        width: 250,
      },
      perPage: 6,
    },
    PageArticleListCategory: {
      actionsOnBottom: false,
      disableBreadcrumbs: false,
      header: {
        enable: true,
        enableBreadcrumbsFirstItem: false,
        fullViewportWidthWrapper: false,
      },
      masterCategoryPrefix: '',
      sidebar: {
        enable: false,
      },
    },
    PageCategoryList: {
      header: {
        enable: true,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: false,
        position: 'right', // `left`, `right`
      },
    },
    PageMagazineList: {
      header: {
        enable: true,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: false,
        position: 'right', // `left`, `right`
      },
    },
    PageProductDetail: {
      accessoryProducts: {
        perLine: {
          xs: 1,
          md: 2,
          xl: 4,
        },
      },
    },
    SearchInput: {
      enableDropdown: false,
    },
  },
  layouts: {
    LayoutBase: {
      headerStickyEnable: false,
      headerMainMenuEnable: false,
    },
  },
  siteCode: 'blog',
}

export default merge(themeSettings, overloadSettings)
