<script>
import SeoMixin from '~/sites/nobilis/mixins/SeoMixin'

export default {
  extends: SeoMixin,
  methods: {
    getSeoMetaKeywords() {
      return []
    },
    seoTitleSuffix(title) {
      return `${title} | ${this.getSeoShortTitle()}`
    },
  },
}
</script>
