<script>
import AppRouteMixin from '~/sites/shop/mixins/AppRouteMixin'
export default {
  extends: AppRouteMixin,
  async beforeMount() {
    await this.$store.dispatch('article/FETCH_CATEGORIES')
    await this.$store.dispatch('article/FETCH_TAGS')
  },
}
</script>
