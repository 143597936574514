import { actions as parentActions } from '~/sites/nobilis/store/homepage'
import articleFragments from '~/queries/fragments/article'
import gql from 'graphql-tag'

const actions = {
  ...parentActions,
  async LOAD_HOMEPAGE_CONFIGURATION({ rootState, dispatch }) {
    try {
      const { siteCode } = this.$themeSettings || {}
      const filterArticles = articles => {
        if (articles && siteCode) {
          //articles = articles.filter(a => ((a || {}).site_enabled || []).includes(siteCode))
        }
        return articles
      }
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          ${articleFragments.box}
          query {
            configuration_blog_homepage {
              articles {
                entity {
                  ...articleFragmentsBox
                }
              }
              tag_cards {
                ... on related_tags {
                  related_tags {
                    entity {
                      ... on article_tag {
                        _id
                        page_meta {
                          slug
                        }
                        title
                        perex
                        image {
                          alt
                          path
                        }
                      }
                    }
                  }
                }
              }
              sidebar_articles {
                entity {
                  ...articleFragmentsBox
                }
              }
              sidebar_tags {
                entity {
                  ... on article_tag {
                    page_meta {
                      slug
                    }
                    title
                    perex
                  }
                }
              }
              sidebar_course {
                entity {
                  ... on StoreProduct {
                    data
                  }
                }
              }
              sidebar_magazine {
                entity {
                  ... on magazine {
                    title
                    perex
                    magazine_file {
                      alt
                      path
                      title
                    }
                    image {
                      alt
                      path
                      title
                    }
                    related_articles {
                      entity {
                        ...articleFragmentsBox
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      })
      const { configuration_blog_homepage } = query.data || {}
      let { articles, tag_cards, sidebar_tags, sidebar_articles, sidebar_magazine, sidebar_course } =
        configuration_blog_homepage || {}
      let configuration = {}
      if ((articles || []).length > 0) {
        configuration.articles = filterArticles(articles.map(entity => entity.entity))
      }
      if ((tag_cards || []).length > 0) {
        tag_cards = tag_cards.map(entity => entity.related_tags.map(entity => entity.entity))
        const { length } = tag_cards
        // FETCH articles for each first tag in card
        if (length) {
          for (let i = 0; i < length; i++) {
            const { length: l } = tag_cards[i]
            if (l) {
              const { _id } = tag_cards[i][0]
              let { entities } = await dispatch(
                'article/LOAD_TAG_ARTICLES',
                {
                  tagId: _id,
                  page: 0,
                  perPage: 3,
                },
                { root: true },
              )
              // Sort out articles with publish date in the future
              entities = (entities || []).filter(
                article => Date.parse(article.date_published ? article.date_published : 0) < Date.now(),
              )
              tag_cards[i][0].articles = entities
            }
          }
        }
        configuration.tag_cards = tag_cards
      }
      if ((sidebar_tags || []).length > 0) {
        configuration.sidebar_tags = sidebar_tags.map(entity => entity.entity)
      }
      if ((sidebar_articles || []).length > 0) {
        configuration.sidebar_articles = filterArticles(sidebar_articles.map(entity => entity.entity))
      }
      if ((sidebar_magazine || []).length > 0) {
        configuration.sidebar_magazine = sidebar_magazine.map(entity => entity.entity)
        configuration.sidebar_magazine[0].related_articles = filterArticles(
          configuration.sidebar_magazine[0].related_articles.map(entity => entity.entity),
        )
      }
      if ((sidebar_course || []).length > 0) {
        configuration.sidebar_course = sidebar_course.map(entity => entity.entity)
      }
      return configuration
    } catch (e) {
      console.error(e)
    }
  },
}

export { actions }
