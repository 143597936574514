import articleFragments from '~/queries/fragments/article'
import gql from 'graphql-tag'

export const actions = {
  async LOAD_MAGAZINES({}, { page, perPage }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${articleFragments.box}
          query magazine($skip: Int!, $limit: Int!) {
            magazine(skip: $skip, limit: $limit) {
              entities {
                title
                perex
                image {
                  title
                  path
                  alt
                }
                issuu
                magazine_file {
                  title
                  path
                  alt
                }
                related_articles {
                  entity {
                    ...articleFragmentsBox
                  }
                }
              }
            }
          }
        `,
        variables: {
          skip: page * perPage,
          limit: perPage,
        },
      })
      return result.data.magazine
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
