import AddToCart from '~/themes/nobilis/components/cart/AddToCart'

export default {
  extends: AddToCart,
  computed: {
    buttonPath() {
      let query = {
        addToCart: true,
        quantity: this.quantity,
        variant_id: this.variant ? this.variant.id : this.product.defaultVariantId,
      }
      const path = this.localePath({
        path: '/',
        ...{ query },
      })
      return this.product.absoluteUrl + path.slice(1)
    },
  },
  methods: {
    click() {
      if ((!this.variant && this.variantRequired) || !this.enabled) {
        return
      }
      this.$emit('click')
      window.open(this.buttonPath, '_blank')
    },
  },
}
