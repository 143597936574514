import HeaderMain from '~/themes/nobilis/components/shop/HeaderMain'

export default {
  extends: HeaderMain,
  methods: {
    initCartInfoDropdown() {
      return
    },
  },
}
