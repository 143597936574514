var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"VerticalMenuItem",class:['VerticalMenuItem--level' + _vm.level]},[_c('div',{staticClass:"VerticalMenuItem__linkWrapper"},[_c('nuxt-link',{staticClass:"VerticalMenuItem__link",class:['VerticalMenuItem__link--level' + _vm.level],attrs:{"to":_vm.localePath(_vm.tag ? {
        name: 'articleTag-slug',
        params: { slug: _vm.slug }
      } : {
        ...(_vm.parent ? {
          name: 'articleList-slug-child',
          params: {
            slug: _vm.parent,
            child: _vm.slug
          }
        }: {
          name: 'articleList-slug',
          params: { slug: _vm.slug }
        })
      })}},[_vm._v(" "+_vm._s(_vm.link.menu_title ? _vm.link.menu_title : _vm.link.title)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }