import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import Divider from '@theme/components/molecule/Divider'
import ProductBox from '@theme/components/product/ProductBox'
import ProductTeaser from '@theme/components/product/ProductTeaser'
import ProductsRecommended from '@theme/components/product/ProductsRecommended'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import TopCategories from '@theme/components/category/TopCategories'
import UniqueSellingPoints from '@theme/components/shop/UniqueSellingPoints'

export default {
  components: {
    AppTitle,
    Breadcrumbs,
    Divider,
    ProductBox,
    ProductTeaser,
    ProductsRecommended,
    ProductsVisited,
    TopCategories,
    UniqueSellingPoints,
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('PageInternalError.breadcrumbs.index'),
          path: '/',
        },
      ],
    }
  },
  head() {
    return this.getSeoHead({ yamlKey: 'PageInternalError' })
  },
  mixins: [SeoMixin],
}
