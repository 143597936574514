import ArticleMenu from '@theme/components/content/ArticleMenu'
import BreadcrumbFirstItem from '@theme/components/molecule/BreadcrumbFirstItem'
import ContentHeader from '~/themes/base/components/content/ContentHeader'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'
import Dropdown from '@theme/components/utils/Dropdown'
import SearchInput from '@theme/components/shop/SearchInput'

export default {
  extends: ContentHeader,
  props: {
    actionsOnBottom: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: [Array, Boolean],
    contentClass: [String, Boolean],
    description: [Object, Boolean],
    disableBreadcrumbs: Boolean,
    enableBreadcrumbsFirstItemInContent: {
      type: Boolean,
      default: false,
    },
    imageSource: [String, Boolean],
    imageClass: [String, Boolean],
  },
  components: {
    ArticleMenu,
    BreadcrumbFirstItem,
    Dropdown,
    SearchInput,
  },
  computed: {
    getCategories() {
      return this.$store.state.article.categories
    },
    getTags() {
      return this.$store.state.article.tags
    },
  },
  mixins: [DetectMobileMixin],
}
