import SearchInput from '~/themes/base/components/shop/SearchInput'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  extends: SearchInput,
  data() {
    return {
      query: this.$store.state.search.catalog.query,
    }
  },
  methods: {
    debounceSearch() {
      return
    },
    dropdownHide() {
      return
    },
    dropdownShow() {
      return
    },
    search() {
      return
    },
    goToSearchResults() {
      this.$store.dispatch('search/CHANGE_QUERY', { location: 'catalog', query: this.query })
      this.$eventBus.$emit(eventDefinitions.SEARCH.QUERY, {
        location: 'catalog',
        query: this.query,
      })
      this.$router.push(this.localePath({ name: 'search', query: { s: this.query } }))
    },
  },
}
