<template>
  <LayoutBase>
    <PageInternalError v-if="error.statusCode === 500"/>
    <PageNotFound
      v-if="error.statusCode === 404"
      site="www"/>
  </LayoutBase>
</template>

<script>
import AppRouteMixin from '~/mixins/AppRouteMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import PageInternalError from '@theme/components/shop/PageInternalError'
import PageNotFound from '@theme/components/shop/PageNotFound'

export default {
  scrollToTop: true,
  components: {
    AppRouteMixin,
    LayoutBase,
    PageInternalError,
    PageNotFound,
  },
  mixins: [AppRouteMixin],
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>
