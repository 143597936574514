import ArticleMenu from '~/themes/base/components/content/ArticleMenu'

export default {
  extends: ArticleMenu,
  props: {
    links: {
      type: Array,
      required: true,
    },
    tag: {
      type: Boolean,
      default: false,
    },
  },
}
