import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5ebd4ba0 = () => interopDefault(import('../sites/blog/pages/magazineList/index.vue' /* webpackChunkName: "sites/blog/pages/magazineList/index" */))
const _386f9446 = () => interopDefault(import('../sites/blog/pages/search/index.vue' /* webpackChunkName: "sites/blog/pages/search/index" */))
const _247ddd2c = () => interopDefault(import('../sites/blog/pages/articleList/index.vue' /* webpackChunkName: "sites/blog/pages/articleList/index" */))
const _7fcbca2c = () => interopDefault(import('../sites/blog/pages/sitemapconfig.vue' /* webpackChunkName: "sites/blog/pages/sitemapconfig" */))
const _37a24647 = () => interopDefault(import('../sites/blog/pages/index.vue' /* webpackChunkName: "sites/blog/pages/index" */))
const _400fd0e2 = () => interopDefault(import('../sites/blog/pages/articleTag/index.vue' /* webpackChunkName: "sites/blog/pages/articleTag/index" */))
const _5d35ce26 = () => interopDefault(import('../sites/blog/pages/article/_slug.vue' /* webpackChunkName: "sites/blog/pages/article/_slug" */))
const _1bef7132 = () => interopDefault(import('../sites/blog/pages/articleList/_slug/index.vue' /* webpackChunkName: "sites/blog/pages/articleList/_slug/index" */))
const _3e592f9a = () => interopDefault(import('../sites/blog/pages/articleTag/_slug.vue' /* webpackChunkName: "sites/blog/pages/articleTag/_slug" */))
const _d06f21be = () => interopDefault(import('../sites/blog/pages/author/_slug.vue' /* webpackChunkName: "sites/blog/pages/author/_slug" */))
const _16fbbfe5 = () => interopDefault(import('../sites/blog/pages/page/_slug.vue' /* webpackChunkName: "sites/blog/pages/page/_slug" */))
const _72a5a250 = () => interopDefault(import('../sites/blog/pages/articleList/_slug/_child.vue' /* webpackChunkName: "sites/blog/pages/articleList/_slug/_child" */))
const _35eba4ff = () => interopDefault(import('../sites/blog/pages/_slug.vue' /* webpackChunkName: "sites/blog/pages/_slug" */))
const _2f6505d4 = () => interopDefault(import('../sites/blog/pages/_.vue' /* webpackChunkName: "sites/blog/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/casopisy/",
    component: _5ebd4ba0,
    pathToRegexpOptions: {"strict":true},
    name: "magazineList___cs"
  }, {
    path: "/hledat/",
    component: _386f9446,
    pathToRegexpOptions: {"strict":true},
    name: "search___cs"
  }, {
    path: "/rubriky/",
    component: _247ddd2c,
    pathToRegexpOptions: {"strict":true},
    name: "articleList___cs"
  }, {
    path: "/sitemapconfig/",
    component: _7fcbca2c,
    pathToRegexpOptions: {"strict":true},
    name: "sitemapconfig___cs"
  }, {
    path: "/sk/",
    component: _37a24647,
    pathToRegexpOptions: {"strict":true},
    name: "index___sk"
  }, {
    path: "/tagy/",
    component: _400fd0e2,
    pathToRegexpOptions: {"strict":true},
    name: "articleTag___cs"
  }, {
    path: "/sk/articleList/",
    component: _247ddd2c,
    pathToRegexpOptions: {"strict":true},
    name: "articleList___sk"
  }, {
    path: "/sk/articleTag/",
    component: _400fd0e2,
    pathToRegexpOptions: {"strict":true},
    name: "articleTag___sk"
  }, {
    path: "/sk/magazineList/",
    component: _5ebd4ba0,
    pathToRegexpOptions: {"strict":true},
    name: "magazineList___sk"
  }, {
    path: "/sk/search/",
    component: _386f9446,
    pathToRegexpOptions: {"strict":true},
    name: "search___sk"
  }, {
    path: "/sk/sitemapconfig/",
    component: _7fcbca2c,
    pathToRegexpOptions: {"strict":true},
    name: "sitemapconfig___sk"
  }, {
    path: "/",
    component: _37a24647,
    pathToRegexpOptions: {"strict":true},
    name: "index___cs"
  }, {
    path: "/sk/article/:slug?/",
    component: _5d35ce26,
    pathToRegexpOptions: {"strict":true},
    name: "article-slug___sk"
  }, {
    path: "/sk/articleList/:slug/",
    component: _1bef7132,
    pathToRegexpOptions: {"strict":true},
    name: "articleList-slug___sk"
  }, {
    path: "/sk/articleTag/:slug/",
    component: _3e592f9a,
    pathToRegexpOptions: {"strict":true},
    name: "articleTag-slug___sk"
  }, {
    path: "/sk/author/:slug?/",
    component: _d06f21be,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___sk"
  }, {
    path: "/sk/page/:slug?/",
    component: _16fbbfe5,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___sk"
  }, {
    path: "/sk/articleList/:slug/:child/",
    component: _72a5a250,
    pathToRegexpOptions: {"strict":true},
    name: "articleList-slug-child___sk"
  }, {
    path: "/autori/:slug/",
    component: _d06f21be,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___cs"
  }, {
    path: "/rubrika/:slug/",
    component: _1bef7132,
    pathToRegexpOptions: {"strict":true},
    name: "articleList-slug___cs"
  }, {
    path: "/sk/:slug/",
    component: _35eba4ff,
    pathToRegexpOptions: {"strict":true},
    name: "slug___sk"
  }, {
    path: "/stranka/:slug/",
    component: _16fbbfe5,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___cs"
  }, {
    path: "/tag/:slug/",
    component: _3e592f9a,
    pathToRegexpOptions: {"strict":true},
    name: "articleTag-slug___cs"
  }, {
    path: "/rubrika/:slug/:child/",
    component: _72a5a250,
    pathToRegexpOptions: {"strict":true},
    name: "articleList-slug-child___cs"
  }, {
    path: "/sk/*/",
    component: _2f6505d4,
    pathToRegexpOptions: {"strict":true},
    name: "all___sk"
  }, {
    path: "/:slug/",
    component: _5d35ce26,
    pathToRegexpOptions: {"strict":true},
    name: "article-slug___cs"
  }, {
    path: "/:slug/",
    component: _35eba4ff,
    pathToRegexpOptions: {"strict":true},
    name: "slug___cs"
  }, {
    path: "/*/",
    component: _2f6505d4,
    pathToRegexpOptions: {"strict":true},
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
