import { get } from 'lodash'
import VerticalMenu from '@theme/components/shop/VerticalMenu'

export default {
  components: {
    VerticalMenu,
  },
  props: {
    currentBreadcrumbs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getLinks() {
      function getLinks(items) {
        let output = []
        items.forEach(i => {
          if (i.__typename === 'article_category') {
            output.push({
              article_category_link: [
                {
                  entity: i,
                },
              ],
              link_type: i.__typename,
              title: i.title,
            })
          }
        })
        return output
      }
      return (
        get(this.$store.state, 'menu.article.menuItems') ||
        getLinks(get(this.$store.state, 'article.categories') || []) ||
        []
      )
    },
  },
}
