import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../sites/blog/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_nuxtenv_6d1103b8 from 'nuxt_plugin_nuxtenv_6d1103b8' // Source: ./nuxt-env.js (mode: 'all')
import nuxt_plugin_pluginutils_6d62e8ca from 'nuxt_plugin_pluginutils_6d62e8ca' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_564c6b95 from 'nuxt_plugin_pluginrouting_564c6b95' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_2b652ffa from 'nuxt_plugin_pluginmain_2b652ffa' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_vuescrollto_3bad9758 from 'nuxt_plugin_vuescrollto_3bad9758' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_portalvue_058f8562 from 'nuxt_plugin_portalvue_058f8562' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_5ca7d18e from 'nuxt_plugin_cookieuniversalnuxt_5ca7d18e' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_apollomodule_596b3746 from 'nuxt_plugin_apollomodule_596b3746' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_axios_b5e48cde from 'nuxt_plugin_axios_b5e48cde' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_libplugin7b0e7ea9_63861c62 from 'nuxt_plugin_libplugin7b0e7ea9_63861c62' // Source: ./lib.plugin.7b0e7ea9.js (mode: 'all')
import nuxt_plugin_moment_0b550a27 from 'nuxt_plugin_moment_0b550a27' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_redirect_368ca365 from 'nuxt_plugin_redirect_368ca365' // Source: ../plugins/redirect.js (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'client')
import nuxt_plugin_catalogEventBus_78a506ce from 'nuxt_plugin_catalogEventBus_78a506ce' // Source: ../plugins/catalogEventBus.js (mode: 'all')
import nuxt_plugin_contentSettings_c8d6229e from 'nuxt_plugin_contentSettings_c8d6229e' // Source: ../plugins/contentSettings.js (mode: 'all')
import nuxt_plugin_currency_37da79e0 from 'nuxt_plugin_currency_37da79e0' // Source: ../plugins/currency.js (mode: 'all')
import nuxt_plugin_dataLayer_64013b46 from 'nuxt_plugin_dataLayer_64013b46' // Source: ../plugins/dataLayer.js (mode: 'client')
import nuxt_plugin_events_71bdff88 from 'nuxt_plugin_events_71bdff88' // Source: ../plugins/events.js (mode: 'client')
import nuxt_plugin_filters_2dd71012 from 'nuxt_plugin_filters_2dd71012' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_locale_28023ea7 from 'nuxt_plugin_locale_28023ea7' // Source: ../plugins/locale.js (mode: 'all')
import nuxt_plugin_localStorage_830ec59e from 'nuxt_plugin_localStorage_830ec59e' // Source: ../plugins/localStorage.js (mode: 'client')
import nuxt_plugin_notifications_15a88b85 from 'nuxt_plugin_notifications_15a88b85' // Source: ../plugins/notifications.js (mode: 'client')
import nuxt_plugin_pluralization_624d5d0d from 'nuxt_plugin_pluralization_624d5d0d' // Source: ../plugins/pluralization.js (mode: 'all')
import nuxt_plugin_promiseAllValues_f1fd230e from 'nuxt_plugin_promiseAllValues_f1fd230e' // Source: ../plugins/promiseAllValues.js (mode: 'all')
import nuxt_plugin_routes_79dccfb7 from 'nuxt_plugin_routes_79dccfb7' // Source: ../plugins/routes.js (mode: 'all')
import nuxt_plugin_sharedMutations_227111c8 from 'nuxt_plugin_sharedMutations_227111c8' // Source: ../plugins/sharedMutations.js (mode: 'client')
import nuxt_plugin_svgicon_445efc57 from 'nuxt_plugin_svgicon_445efc57' // Source: ../plugins/svgicon (mode: 'all')
import nuxt_plugin_themeSettings_7c57c0e1 from 'nuxt_plugin_themeSettings_7c57c0e1' // Source: ../plugins/themeSettings.js (mode: 'all')
import nuxt_plugin_tooltip_a5fff1ec from 'nuxt_plugin_tooltip_a5fff1ec' // Source: ../plugins/tooltip.js (mode: 'client')
import nuxt_plugin_touchEvents_091462b5 from 'nuxt_plugin_touchEvents_091462b5' // Source: ../plugins/touchEvents.js (mode: 'client')
import nuxt_plugin_utm_339bcf02 from 'nuxt_plugin_utm_339bcf02' // Source: ../plugins/utm.js (mode: 'all')
import nuxt_plugin_veeValidate_704b86be from 'nuxt_plugin_veeValidate_704b86be' // Source: ../plugins/veeValidate.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"cs"},"bodyAttrs":{"itemscope":"itemscope","itemtype":"http:\u002F\u002Fschema.org\u002FWebPage"},"title":"","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"theme-color","content":"#ffffff"},{"name":"msapplication-config","content":"\u002Ffavicon\u002Fbrowserconfig.xml"},{"name":"msapplication-TileColor","content":"#588075"},{}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Ffavicon\u002Fapple-touch-icon.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicon\u002Ffavicon-16x16.png"},{"rel":"shortcut icon","href":"\u002Ffavicon\u002Ffavicon.ico"},{"rel":"manifest","href":"\u002Ffavicon\u002Fsite.webmanifest"},{"rel":"mask-icon","href":"\u002Ffavicon\u002Fsafari-pinned-tab.svg","color":"#588075"},{"rel":"shortcut icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"script":[{"src":"https:\u002F\u002Fgateway.saimon.ai\u002Fnobilis\u002Floader.js","async":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_nuxtenv_6d1103b8 === 'function') {
    await nuxt_plugin_nuxtenv_6d1103b8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_6d62e8ca === 'function') {
    await nuxt_plugin_pluginutils_6d62e8ca(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_564c6b95 === 'function') {
    await nuxt_plugin_pluginrouting_564c6b95(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_2b652ffa === 'function') {
    await nuxt_plugin_pluginmain_2b652ffa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_3bad9758 === 'function') {
    await nuxt_plugin_vuescrollto_3bad9758(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_058f8562 === 'function') {
    await nuxt_plugin_portalvue_058f8562(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_5ca7d18e === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_5ca7d18e(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_596b3746 === 'function') {
    await nuxt_plugin_apollomodule_596b3746(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_b5e48cde === 'function') {
    await nuxt_plugin_axios_b5e48cde(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin7b0e7ea9_63861c62 === 'function') {
    await nuxt_plugin_libplugin7b0e7ea9_63861c62(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_0b550a27 === 'function') {
    await nuxt_plugin_moment_0b550a27(app.context, inject)
  }

  if (typeof nuxt_plugin_redirect_368ca365 === 'function') {
    await nuxt_plugin_redirect_368ca365(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  if (typeof nuxt_plugin_catalogEventBus_78a506ce === 'function') {
    await nuxt_plugin_catalogEventBus_78a506ce(app.context, inject)
  }

  if (typeof nuxt_plugin_contentSettings_c8d6229e === 'function') {
    await nuxt_plugin_contentSettings_c8d6229e(app.context, inject)
  }

  if (typeof nuxt_plugin_currency_37da79e0 === 'function') {
    await nuxt_plugin_currency_37da79e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dataLayer_64013b46 === 'function') {
    await nuxt_plugin_dataLayer_64013b46(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_events_71bdff88 === 'function') {
    await nuxt_plugin_events_71bdff88(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2dd71012 === 'function') {
    await nuxt_plugin_filters_2dd71012(app.context, inject)
  }

  if (typeof nuxt_plugin_locale_28023ea7 === 'function') {
    await nuxt_plugin_locale_28023ea7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localStorage_830ec59e === 'function') {
    await nuxt_plugin_localStorage_830ec59e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_15a88b85 === 'function') {
    await nuxt_plugin_notifications_15a88b85(app.context, inject)
  }

  if (typeof nuxt_plugin_pluralization_624d5d0d === 'function') {
    await nuxt_plugin_pluralization_624d5d0d(app.context, inject)
  }

  if (typeof nuxt_plugin_promiseAllValues_f1fd230e === 'function') {
    await nuxt_plugin_promiseAllValues_f1fd230e(app.context, inject)
  }

  if (typeof nuxt_plugin_routes_79dccfb7 === 'function') {
    await nuxt_plugin_routes_79dccfb7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sharedMutations_227111c8 === 'function') {
    await nuxt_plugin_sharedMutations_227111c8(app.context, inject)
  }

  if (typeof nuxt_plugin_svgicon_445efc57 === 'function') {
    await nuxt_plugin_svgicon_445efc57(app.context, inject)
  }

  if (typeof nuxt_plugin_themeSettings_7c57c0e1 === 'function') {
    await nuxt_plugin_themeSettings_7c57c0e1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tooltip_a5fff1ec === 'function') {
    await nuxt_plugin_tooltip_a5fff1ec(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_touchEvents_091462b5 === 'function') {
    await nuxt_plugin_touchEvents_091462b5(app.context, inject)
  }

  if (typeof nuxt_plugin_utm_339bcf02 === 'function') {
    await nuxt_plugin_utm_339bcf02(app.context, inject)
  }

  if (typeof nuxt_plugin_veeValidate_704b86be === 'function') {
    await nuxt_plugin_veeValidate_704b86be(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
