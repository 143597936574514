import { get } from 'lodash'
import VerticalMenuItem from '~/themes/base/components/shop/VerticalMenuItem'

export default {
  extends: VerticalMenuItem,
  props: {
    tag: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parent() {
      return get(this.link, 'master_category[0].entity.page_meta.slug')
    },
    slug() {
      return get(this.link, 'page_meta.slug')
    },
  },
}
