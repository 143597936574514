import VerticalMenu from '~/themes/base/components/shop/VerticalMenu'
import VerticalMenuItem from '@theme/components/shop/VerticalMenuItem'

export default {
  extends: VerticalMenu,
  components: {
    VerticalMenuItem,
  },
  props: {
    oneColumn: null,
    tag: {
      type: Boolean,
      default: false,
    },
  },
}
