<script>
import ProductBoxMixin from '~/sites/shop/mixins/ProductBoxMixin'
export default {
  extends: ProductBoxMixin,
  computed: {
    productPath() {
      let query = {}
      if (this.activeVariantId && this.activeVariantId !== this.product.defaultVariantId) {
        query.variant_id = this.activeVariantId
      }
      const path = this.localePath({
        path: '/',
        ...{ query },
      })
      return this.product.absoluteUrl + path.slice(1)
    },
  },
}
</script>
